<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Criar campo personalizado</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-4"
                  >Nome do campo</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-4"
                  >Descrição</label
                >
                <div class="col-md-6">
                  <textarea
                    v-model="form.description"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-4"
                  >Campo obrigatório</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.requiredField"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-4"
                  >Campo sensivel</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.sensitiveContent"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-right text-left-m col-md-4"
                  >Disponivel no OMNI</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.appearsOmni"
                    type="checkbox"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :disabled="isSending"
              type="submit"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import Modal from '@/mixins/Modal';
// import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    // VueTelInput,
  },
  mixins: [Modal],
  data() {
    return {
      groups: [],
      isSending: false,
      mobile_number: null,
      invalidPhone: false,
      countryCode: '',
      form: {
        name: '',
        description: '',
        requiredField: false,
        sensitiveContent: false,
        appearsOmni: true,
      },
    };
  },
  methods: {
    save() {
      if (this.form.name) {
        this.isSending = true;
        // const dataSend = this.data;

        axios().post('/contacts/custom-field', this.form).then(() => {
          this.isSending = false;
          this.$emit('created');
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Criado campo personalizado',
            type: 'success',
          });
        }).catch((ee) => {
          const content = ee?.response?.data?.message ?? String(ee);

          this.isSending = false;
          this.$toast.show({
            title: 'Campo personalizado',
            content,
            type: 'danger',
          });
        });
      } else {
        this.$toast.show({
          title: 'Campo personalizado',
          content: 'Falta definir o nome',
          type: 'danger',
        });
      }
    },
  },
};
</script>
